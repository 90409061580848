<template>
  <div class="nantian-judicial">
    <div class="nantian-judicial_left">
      <div class="logo-box">
        <div class="img">LOGO</div>
        <div class="title">南天司法鉴定</div>
        <div class="title">管理系统</div>
      </div>
      <div class="menu-box hide-scrollbar">
        <div class="menu-item">
          <div class="menu-icon">
            <img :src="menuIcon1" alt="" />
          </div>
          <div class="menu-title">业务管理</div>
          <div class="menu-icon-down">
            <img :src="arrowDown" alt="" />
          </div>
        </div>
        <div class="menu-child">
          <div
            class="menu-title"
            v-for="item in menuChild"
            :key="item.title"
            :class="{ active: item.isActive }"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="menu-item" v-for="item in menuGroupList" :key="item">
          <div class="menu-icon-group">
            <img :src="item.menuIcon" alt="" />
          </div>
          <div class="menu-title">{{ item.title }}</div>
          <div class="menu-icon-right">
            <img :src="arrowRight" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="nantian-judicial_right">
      <div class="useinfor-top">
        <div class="box">
          <img class="user-avatar" :src="userAvatar" alt="" />
          <span>声像室-张三</span>
          <img class="message-icon" :src="messageIcon" alt="" />
          <el-icon><SwitchButton /></el-icon>
          <span style="font-size: 20px">退出</span>
          <div class="number">1</div>
        </div>
      </div>
      <div class="breadcrumbs-box">
        <span>当前位置：</span>
        <div class="tab">
          <span>案件登记</span>
          <img :src="closeIcon" alt="" />
        </div>
        <div class="tab active">
          <span>案件管理</span>
          <el-icon><Close /></el-icon>
        </div>
      </div>
      <!-- 内容 -->
      <div class="content-box hide-scrollbar">
        <div class="serach-box">
          <div class="tag">
            <div
              class="tag-item"
              v-for="(item, index) in tagList"
              :key="item"
              :class="{ active: index == 0 }"
            >
              {{ item }}
            </div>
          </div>
          <div class="search">
            <div class="input-box">
              <span>流水号</span>
              <div class="input"></div>
            </div>
            <div class="input-box">
              <span>委托人</span>
              <div class="input"></div>
            </div>
            <div class="input-box">
              <span>委托日期</span>
              <div class="time">
                2016-12-14
                <img :src="timeIcon" alt="" />
              </div>
              &nbsp;-&nbsp;
              <div class="time">
                2016-12-14
                <img :src="timeIcon" alt="" />
              </div>
            </div>
            <div class="input-box">
              <span>智能部门</span>
              <div class="time">
                <div>
                  声像室
                  <img class="arrow" :src="arrowDown2" alt="" />
                </div>
              </div>
            </div>
            <div class="input-box">
              <span>状态</span>
              <div class="time">
                <div>
                  未鉴定
                  <img class="arrow" :src="arrowDown2" alt="" />
                </div>
              </div>
            </div>
            <div class="btn-box">
              <div class="search-btn">搜索</div>
              <div class="search-btn">高级查询</div>
            </div>
            <div class="export">
              <img :src="exportIcon" alt="" />
              <span>导出</span>
            </div>
          </div>
        </div>
        <div class="table-box">
          <div class="top-taglable">
            <div class="title" v-for="item in tableTagList1" :key="item">
              {{ item }}
            </div>
            <div class="line"></div>
          </div>

          <div class="table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="index" label="序号" width="70" />
              <el-table-column prop="a" label="签发" width="120">
                <template #default="{ row }">
                  <el-switch
                    v-model="row.a"
                    class="ml-2"
                    width="50"
                    inline-prompt
                    active-text="签发"
                    inactive-text="解签"
                    style="
                      --el-switch-on-color: #13ce66;
                      --el-switch-off-color: #dfdfdf;
                    "
                  />
                </template>
              </el-table-column>
              <el-table-column prop="b" label="输出" width="90">
                <template #default="{ row }">
                  <div class="btn1">{{ row.b }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="c" label="委托变更" width="100">
                <template #default="{ row }">
                  <div class="btn2">{{ row.c }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="d" label="流水号" width="150" />
              <el-table-column prop="e" label="案件编号" width="210" />
              <el-table-column prop="f" label="案件受理号" width="100" />
              <el-table-column prop="f" label="委托方案号" width="100" />
              <el-table-column prop="g" label="职能部门" width="90" />
              <el-table-column prop="h" label="专业类别" width="90" />
              <el-table-column prop="i" label="紧急程度" width="90" />
              <el-table-column prop="j" label="受理地点" width="90" />
              <el-table-column prop="time" label="委托日期" width="120" />
              <el-table-column prop="f" label="委托单位" width="100" />
              <el-table-column prop="k" label="委托人" width="80" />
              <el-table-column prop="f" label="鉴定对象" width="100" />
              <el-table-column prop="m" label="身份证号码" width="210" />
              <el-table-column prop="n" label="在场人员" width="120" />
              <el-table-column prop="f" label="受理人" width="80" />
              <el-table-column prop="f" label="第一鉴定人" width="100" />
              <el-table-column prop="f" label="指派/选定鉴定人" width="160" />
              <el-table-column prop="time" label="启动日期" width="120" />
              <el-table-column prop="time" label="完成日期" width="120" />
              <el-table-column prop="l" label="鉴定类型" width="100" />
              <el-table-column prop="o" label="状态" width="80" />
              <el-table-column fixed="right" label="退款" width="80">
                <template #default>
                  <div class="btn3">退款</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="退案" width="110">
                <template #default>
                  <div class="btn4">申请退案</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="tag-box">
          <div class="top-taglable">
            <div class="title" v-for="item in tableTagList2" :key="item">
              {{ item }}
            </div>
            <div class="line"></div>
          </div>
          <div class="contract-img">
            <img :src="contractImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  getCurrentInstance,
  reactive,
  onBeforeMount,
  onUnmounted,
} from "vue";
const { proxy } = getCurrentInstance();
const mainStore = proxy.$usePiniaModule("mainStore");

// 菜单图标
const menuIcon1 = require("@/assets/images/imitatepage/nantian-judicial/icon_1.png");
const arrowDown = require("@/assets/images/imitatepage/nantian-judicial/icon_arrow_down.png");
const arrowRight = require("@/assets/images/imitatepage/nantian-judicial/icon_arrow_right.png");

// menuchild
const menuChild = [
  { title: "案件登记" },
  { title: "案件受理" },
  { title: "案件管理", isActive: true },
  { title: "工作接收" },
  { title: "鉴定期限管理" },
];

const menuGroupList = [
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_2.png"),
    title: "档案管理",
    iconWidth: "17px",
    iconHeight: "21px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_3.png"),
    title: "业务统计",
    iconWidth: "18px",
    iconHeight: "20px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_4.png"),
    title: "人力资源",
    iconWidth: "20px",
    iconHeight: "19px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_5.png"),
    title: "采购申请",
    iconWidth: "20px",
    iconHeight: "19px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_6.png"),
    title: "市场管理",
    iconWidth: "19px",
    iconHeight: "18px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_7.png"),
    title: "检验检测",
    iconWidth: "17px",
    iconHeight: "21px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_8.png"),
    title: "财务管理",
    iconWidth: "18px",
    iconHeight: "20px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_9.png"),
    title: "设备仪器",
    iconWidth: "17px",
    iconHeight: "19px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_10.png"),
    title: "认证认可",
    iconWidth: "18px",
    iconHeight: "18px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_11.png"),
    title: "公共功能",
    iconWidth: "16px",
    iconHeight: "16px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_12.png"),
    title: "系统管理",
    iconWidth: "14px",
    iconHeight: "13px",
  },
  {
    menuIcon: require("@/assets/images/imitatepage/nantian-judicial/icon_13.png"),
    title: "客户端",
    iconWidth: "17px",
    iconHeight: "21px",
  },
];

// 表格
const tableData = [
  {
    index: 1,
    name: "序号",
    width: "70",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 2,
    name: "签发",
    width: "80",
    a: true,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 3,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 4,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 5,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 6,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 7,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 8,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
  {
    index: 9,
    name: "签发",
    width: "80",
    a: false,
    b: "输出",
    c: "委托变更",
    d: "201812031234",
    e: "粤南[2018]声鉴字第1403号",
    f: "xxxxx",
    g: "声像室",
    h: "声像资料",
    i: "普通",
    j: "本所",
    time: "2019-01-01",
    k: "成大大",
    m: "441521199306064105",
    n: "xxxx、xxxxx",
    l: "初次",
    o: "鉴定中",
  },
];

// 顶部标签
const tagList = ["案件管理", "委托变更", "案件退款", "案件待退款", "案件退案"];
// 表格标签
const tableTagList1 = ["基本信息", "收费信息"];
const tableTagList2 = [
  "委托合同",
  "委托项目",
  "收费项目",
  "鉴定方法",
  "收费信息",
  "资料检材",
  "对象信息",
  "对象照片",
  "委托描述",
  "合同条款",
  "工作计划",
  "变更信息",
  "关联案件",
];

// 右侧顶部用户信息
const userAvatar = require("@/assets/images/imitatepage/nantian-judicial/icon_user.png");
const messageIcon = require("@/assets/images/imitatepage/nantian-judicial/message-icon.png");
const closeIcon = require("@/assets/images/imitatepage/nantian-judicial/close-icon.png");

// 时间
const timeIcon = require("@/assets/images/imitatepage/nantian-judicial/time-icon.png");
const arrowDown2 = require("@/assets/images/imitatepage/nantian-judicial/icon_arrow_down2.png");
const exportIcon = require("@/assets/images/imitatepage/nantian-judicial/export-icon.png");
const contractImg = require("@/assets/images/imitatepage/nantian-judicial/book-img.png");

// 控制框架组件
const controlLayout = (result) => {
  mainStore.showHeaderNavbar = result;
  mainStore.showDownFooter = result;
};
onBeforeMount(() => {
  controlLayout(false);
});
</script>

<style lang="scss" scoped>
.nantian-judicial {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  cursor: pointer;
  // 左盒子
  &_left {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 240px;
    background-color: #162850;
    height: 100%;

    // logo
    .logo-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 170px;
      border-bottom: 2px solid #455373;
      .img {
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 50%;
        line-height: 80px;
        text-align: center;
        color: #0080d8;
        font-size: 20px;
        margin-bottom: 4px;
      }
      .title {
        font-size: 24px;
        color: white;
      }
    }

    // 菜单
    .menu-box {
      height: calc(100% - 130px);
      overflow-y: auto;
      .menu-item {
        display: flex;
        height: 50px;
        text-align: center;
        line-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 0 0 34px;

        .menu-icon {
          display: flex;
          width: 19px;
          height: 22px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .menu-icon-group {
          display: flex;
          width: 20px;
          height: 19px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .menu-title {
          color: white;
          font-size: 20px;
          margin: 0 50px 0 16px;
          min-width: 80px;
          text-align: start;
        }
        .menu-icon-down {
          display: flex;
          width: 16px;
          height: 9px;
          margin-top: 2px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .menu-icon-right {
          display: flex;
          width: 9px;
          height: 16px;
          margin-top: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      //  子集
      .menu-child {
        .menu-title {
          height: 50px;
          text-align: start;
          line-height: 50px;
          color: white;
          padding-left: 70px;
          box-sizing: border-box;

          &.active {
            background-color: #fff;
            border-left: 4px solid #55a9ff;
            color: #162850;
          }
        }
      }
    }
  }
  // 右盒子
  &_right {
    height: 100%;
    background-color: #f2f2f2;

    .useinfor-top {
      display: flex;
      justify-content: center;
      height: 60px;
      background-color: #fff;
      border-bottom: 2px solid #0080d8;
      .box {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 360px;
        .user-avatar {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }
        .message-icon {
          width: 21px;
          height: 25px;
          margin: 0 70px 0 60px;
        }
        .el-icon {
          font-size: 22px;
          margin-right: 5px;
        }

        .number {
          position: absolute;
          top: 15px;
          left: 200px;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #ff0000;
          border-radius: 50%;
          font-size: 12px;
          color: #fff;
        }
        span:last-child {
          font-size: 20px;
        }
      }
    }

    .breadcrumbs-box {
      display: flex;
      align-items: center;
      padding-left: 30px;
      height: 50px;
      background-color: #fff;
      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 116px;

        img {
          width: 19px;
          height: 22px;
          margin-left: 8px;
        }

        .el-icon {
          color: white;
          margin: 3px 0 0 8px;
        }

        &.active {
          color: white;
          background: #0080d8;
        }
      }
    }

    // 内容
    .content-box {
      width: calc(100vw - 260px);
      margin: 10px;
      height: calc(100vh - 132px);
      border-radius: 6px;
      overflow-y: auto;

      .serach-box {
        background-color: #fff;

        .tag {
          display: flex;
          align-items: center;
          height: 50px;
          font-size: 18px;
          border-bottom: 2px solid #e5e5e5;

          .tag-item {
            min-width: 95px;
            height: 100%;
            line-height: 50px;
            text-align: center;

            &.active {
              background-color: #fff;
              background: #0080d8;
              color: white;
            }
          }
        }

        .search {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 16px 0 16px 15px;
          color: #333333;

          .input-box {
            display: flex;
            align-items: center;
            margin-right: 25px;
            margin-bottom: 15px;

            span {
              margin-right: 8px;
            }

            .input {
              width: 160px;
              height: 32px;
              border: 1px solid #e0e0e0;
              border-radius: 4px;
            }

            .time {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 123px;
              height: 32px;
              background: #f1f2f7;
              border-radius: 4px;
              img {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }

              div {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                .arrow {
                  width: 11px;
                  height: 6px;
                }
              }
            }
          }

          .btn-box {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .search-btn {
              width: 99px;
              height: 32px;
              background: #0080d8;
              border-radius: 16px;
              color: #fff;
              line-height: 32px;
              text-align: center;
              margin-right: 30px;
            }
          }

          .export {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            img {
              width: 22px;
              height: 19px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    // 表格
    .table-box {
      background: #ffffff;
      border-radius: 4px;
      margin-top: 10px;

      .top-taglable {
        position: relative;
        height: 52px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 110px;
          height: 100%;

          &:first-child {
            color: #0080d8;
          }
        }
        .line {
          position: absolute;
          top: 51px;
          left: 13px;
          width: 85px;
          height: 2px;
          background-color: #0080d8;
        }
      }

      .table {
        overflow: hidden;
        height: 500px;

        .btn1 {
          width: 58px;
          height: 24px;
          background: #0080d8;
          border-radius: 12px;
          text-align: center;
          line-height: 24px;
          color: white;
          font-size: 15px;
        }

        .btn2 {
          width: 86px;
          height: 24px;
          background: #e25556;
          border-radius: 12px;
          text-align: center;
          line-height: 24px;
          color: white;
          font-size: 15px;
        }
        .btn3 {
          width: 58px;
          height: 24px;
          background: #f32c11;
          border-radius: 12px;
          text-align: center;
          line-height: 24px;
          color: white;
          font-size: 15px;
        }
        .btn4 {
          width: 86px;
          height: 24px;
          background: #ff9f19;
          border-radius: 12px;
          text-align: center;
          line-height: 24px;
          color: white;
          font-size: 15px;
        }

        .container {
          // display: grid;
          // grid-template-rows: auto 1fr;
          .tab-title {
            display: flex;
            align-items: center;
            width: 100%;
            height: 33px;
            background: linear-gradient(0deg, #008beb 0%, #37aeff 100%);

            span {
              display: inline-block;
            }
          }
        }
      }
    }

    .tag-box {
      display: grid;
      grid-template-rows: 52px 1fr;
      margin-top: 20px;
      background: #ffffff;
      border-radius: 4px;
      .top-taglable {
        position: relative;
        height: 52px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 110px;
          height: 100%;

          &:first-child {
            color: #0080d8;
          }
        }
        .line {
          position: absolute;
          top: 51px;
          left: 13px;
          width: 85px;
          height: 2px;
          background-color: #0080d8;
        }
      }

      .contract-img {
        width: 610px;
        height: 535px;
        margin: 10px 10px 50px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.nantian-judicial {
  .el-switch {
    .el-switch__inner {
      .is-text {
        color: #4b4b4b !important;
      }
    }

    &.is-checked {
      .el-switch__inner {
        .is-text {
          color: #fff !important;
        }
      }
    }
  }
}
</style>
